@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.Wrapper {
  .Video {
    border-radius: 12px;
    width: 50%;
    height: auto;
    @include breakpoint($bk-phone) {
      width: 100%;
      margin-top: 48px;
    }
  }
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(9, 9, 9);
  padding: 48px 0;

  .WrapperName {
    width: max-content;
    background: #ffd937;
    padding: 12px 24px;
    border-radius: 12px;
    margin: 12px 0;
    @include breakpoint($bk-phone) {
      width: 100%;
    }
  }

  .Name {
    font-size: 24px;
  }

  .FirstBlock {
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    transition: 1s;
    padding: 0 24px;
    gap: 24px;
    @include breakpoint($bk-phone) {
      height: auto;
    }

    .Action {
      background-color: #171717;
      padding: 80px;
      border-radius: 24px;
    }

    @include breakpoint($bk-phone) {
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
  }

  .FirstBlock:hover {
    width: 100%;
    background-color: unset;
    transition: 0.2s;
  }

  .SecondBlock {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 0 24px;

    .Gallery {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: wrap;
      gap: 48px;
      justify-content: center;
      align-items: center;


      .BlockArt {
        width: 100%;
        justify-content: center;
        align-items: center;
        @include breakpoint($bk-phone) {
          flex-flow: column;
        }
        .Art {
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }
  }
}
