@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.VkSection {
  background-image: url("../../../../public/VkAssets/bomb.png");
  background-size: 100%;
  height: auto;
}

.MainWrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  //<-------------------------------------------------------- Первый блок
  .FirstBlock {
    width: auto;
    height: 100vh;
    background-image: url("../../../../public/VkAssets/Vkbg.png");
    background-size: 100%;

    display: flex;
    align-items: center;
    justify-content: end;
    @include breakpoint($bk-phone) {
      padding: 24px;
    }

    .BlockForTitle {
      height: auto;
      width: 500px;
      border-radius: 40px;
      background-color: #0077FF;
      box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
      0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
      0px 13.4018px 17.869px rgb(15 15 15 / 5%),
      0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
      0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
      0px 1.66035px 2.21381px rgb(15 15 15 / 2%);

      display: flex;
      flex-flow: column;
      align-items: center;

      padding: 40px;
      margin-right: 10%;
      @include breakpoint($bk-phone) {
        margin: 0;
      }
      @include breakpoint($bk-table-landscape) {
        margin-right: 5%;
      }

      .NameTitle {
        color: #fff;
        margin: 12px 0 12px 0;
      }
    }
  }

  //<--------------------------------------------------------  Вводные / Задача / Второй блок
  .SecondBlock {
    width: auto;
    height: 110vh;

    display: grid;
    grid-template-columns: 50% 50%;
    transition: 0.4s;
    @include breakpoint($bk-table) {
      grid-template-columns: auto;
      height: auto;
      width: auto;
    }
    @include breakpoint($bk-phone) {
      grid-template-columns: auto;
      height: auto;
    }
    //@include breakpoint($bk-table-landscape) {
    //  grid-template-columns: auto;
    //  height: auto;
    //}

    .WrapperTask {
      width: auto;
      height: auto;
      max-width: 800px;
      border-radius: 18px;
      padding: 48px;

      display: flex;
      align-items: center;
      text-align: justify;

      @include breakpoint($bk-phone) {
        padding: 24px;
      }

      .TaskBlock {
        width: auto;
        height: 100%;
        display: grid;

        .IlBlock {
          width: 320px;
          height: auto;

          .IllustrationTask {
            height: auto;
            width: auto;
            object-fit: cover;
            opacity: 40%;
            transition: 0.4s;

            @include breakpoint($bk-phone) {
              width: 270px;
              margin-top: 24px;
            }
          }

          .IllustrationTask:hover {
            opacity: 100%;
            transition: 0.4s;
          }
        }

        @include breakpoint($bk-phone) {
          grid-template-columns: auto;
        }
      }

      .NameTitle {
        font-size: 120px;
        color: #080808;
        @include breakpoint($bk-phone) {
          font-size: 52px;
          margin-bottom: 24px;
        }
        @include breakpoint($bk-table-landscape) {
          font-size: 52px;
          margin-bottom: 24px;
        }
      }
    }
  }

  .SecondBlock:hover {
    background-color: #080808;
    transition: 0.4s;

    .NameTitle {
      color: #C3CCE9;
    }

    .MainText {
      color: #C3CCE9;
    }
  }

  .MainText {
    width: auto;
    height: auto;
    color: #080808;
    font-size: 18px;
    text-align: justify;
  }

  //<-------------------------------------------------------- Этапы работы
  .WrapperInfo {
    display: grid;
    width: auto;
    height: 110vh;
    transition: 0.4s;
    justify-content: center;
    @include breakpoint($bk-table) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 65vh;
    }
    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 65vh;
    }
    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 24px 0 24px 0;
    }


    .WorkExp {
      //@include breakpoint($bk-phone) {
      //  display: none;
      //}
      .ImgMaket {
        padding: 24px;
        width: 100%;
        height: auto;
      }
    }

    .WorkExpMobile {
      display: none;
      @include breakpoint($bk-phone) {
        display: flex;
        flex-flow: column;
        justify-content: center;
      }
    }

    .InfoBlock {
      width: auto;
      height: auto;
      //max-width: 800px;
      border-radius: 18px;

      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      padding: 48px;
      @include breakpoint($bk-phone) {
        padding: 0;
      }

      .NameTitle {
        font-size: 120px;
        color: #080808;
        @include breakpoint($bk-phone) {
          font-size: 52px;
          margin-bottom: 24px;
        }
        @include breakpoint($bk-table-landscape) {
          font-size: 52px;
          margin-bottom: 24px;
        }
      }
    }
  }

  .WrapperInfo:hover {
    background-color: #080808;
    transition: 0.4s;

    .Title {
      color: #C3CCE9;
    }

    .NameTitle {
      color: #C3CCE9;
    }

    .MainText {
      color: #C3CCE9;
    }
  }

  //<-------------------------------------------------------- UI KIT / Information
  .WrapperUIKIT {
    display: grid;
    grid-template-columns: 50% 50%;
    width: auto;
    height: 110vh;
    transition: 0.4s;
    justify-content: center;
    color: #080808;
    @include breakpoint($bk-table) {
      grid-template-columns: auto;
      height: 100vh;
    }
    @include breakpoint($bk-phone) {
      grid-template-columns: auto;
      height: 100vh;
    }

    .WrapperTextUIKIT {
      width: 100%;
      font-size: 18px;
      text-align: justify;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px;

      .TextUIKIT {
        max-width: 400px;
      }
    }

    .BackgroundUIKIT {
      background-image: url("../../../../public/VkAssets/UI.png");
      background-size: cover;
      //background-repeat: no-repeat;
      width: 100%;
      height: auto;
    }
  }

  .WrapperUIKIT:hover {
    background-color: #080808;
    transition: 0.4s;
    color: #C3CCE9;
  }

  //<-------------------------------------------------------- Галлерея
  .ThirdBlock {
    display: flex;
    flex-flow: column;
    width: auto;
    justify-content: center;
    height: 100%;
    margin-top: 24px;

    .ImgMaket {
      width: 100%;
      height: 100%;
    }

    .GalleryNavigation {
      display: flex;
      justify-content: center;

      .GalleryBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 45px;

        border-radius: 18px;
        border: 2px solid #080808;
        margin: 24px;
        transition: 0.4s;
        background-color: transparent;

        font-family: Gilroy, sans-serif;
        font-size: 18px;
      }

      .GalleryBtn:hover {
        background-color: #080808;
        transition: 0.4s;
        color: #C3CCE9;
      }
    }
  }

  //<-------------------------------------------------------- Мокапы
  .FourthBlock {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: auto;
    height: auto;
    padding-top: 24px;
    transition: 0.4s;
    @include breakpoint($bk-phone) {
      flex-flow: column;
    }

    .Mockups {
      display: flex;
      justify-content: center;
      width: auto;
      height: 100vh;
      align-items: center;



      @include breakpoint($bk-phone) {
        flex-flow: column;
        width: 100%;
        height: auto;
      }
      @include breakpoint($bk-table) {
        flex-flow: column;
        width: 100%;
        height: auto;
      }

      // Компоненты
      .ImgInfo {
        margin: 24px;
        border-radius: 18px;
        background-color: #EBF2FA;
        height: auto;
        width: auto;
        display: flex;
        justify-content: center;

        .ImgMaket {
          width: 80%;
          height: 80%;
        }
      }

      .ImgMockups {
        margin: 0 10px 24px 10px;
        height: auto;
        width: auto;
        @include breakpoint($bk-phone) {
          flex-flow: column;
          margin: 10px 0 10px 0;
        }
        @include breakpoint($bk-table-landscape) {
          width: 20%;
          height: auto;
        }
      }
    }
  }

  //<-------------------------------------------------------- Video
  .FifthBlock {
    width: auto;
    height: 110vh;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;

    .WrapperVideo {
      width: 80%;
      height: 80%;
      position: absolute;
    }

    video {
      width: 100%;
      height: 100%;
    }

    .WrapperTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 80%;
      position: absolute;
    }

    .BackgraundTitle {
      font-size: 220px;
      color: #C3CCE9;
      @include breakpoint($bk-phone) {
        font-size: 44px;
      }
      @include breakpoint($bk-table) {
        font-size: 32px;
      }
      @include breakpoint($bk-table-landscape) {
        font-size: 140px;
      }
    }
  }

  //<-------------------------------------------------------- Figma Show Export
  .WrapperForFigma {
    width: 100%;
    height: 70vh;

    display: flex;
    flex-flow: column;
    justify-content: center;
    .FigmaExport {
      width: auto;
      height: 100%;

      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24px;
      @include breakpoint($bk-phone) {
        flex-flow: column;
      }
    }
  }
}

