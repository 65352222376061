@import "~breakpoint-sass";
@import "./src/index.scss";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.Wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.WrapperCoin {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: white;
    transition: 0.4s;

    &:hover {
      color: #2130FD;
      transition: 0.4s;
    }
  }

  .Coin {
    height: 200px;
  }
}

.FooterWrapper {
  width: 100%;
  padding: 40px 40px 40px 40px;
  margin: 24px;
  display: flex;
  flex-flow: column;
  gap: 24px;
  border-radius: 16px;
  background-color: #f1f1f1;
  color: white;
  font-family: Manrope, sans-serif;

  @include breakpoint($bk-phone) {
    padding: 24px 24px 24px 24px;
  }

  .Title {
    margin-left: -0.55%;
    font-size: 120px;
    padding: 10px 0 10px 0;
    @include breakpoint($bk-phone) {
      font-size: 32px;
    }
  }


  .Info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: #212121;

    .NavLink {
      color: #080808;
      transition: 0.4s;
      text-decoration: none;
    }

    .NavLink:hover {
      color: #2130FD;
      transition: 0.4s;
    }

    @include breakpoint($bk-phone) {
      flex-flow: column;
      font-size: 14px;
    }
  }

  .links {
    display: flex;
    justify-content: space-between;

    @include breakpoint($bk-phone) {
      flex-flow: column;
    }

    a {
      width: 100%;
      //min-width: 100px;
      padding: 12px 24px 12px 24px;
      text-align: center;
      text-decoration: none;
      color: #080808;
      font-size: 18px;
      //padding: 8px;
      transition: 0.5s;
      border-radius: 18px;

      @include breakpoint($bk-phone) {
        border-left: 5px solid black;
        text-align: left;
      }
    }

    a:hover {
      background: #2130FD;
      color: #ffffff;
      border-radius: 18px;
      transition: 0.5s;
    }
  }
}
