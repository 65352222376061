@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.MainWrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow: hidden;

  width: auto;
  height: 100%;

  .FirstBlock {
    width: auto;
    height: 100vh;
    background-color: white;
    background-image: url("../../../../public/OpenBank/logo.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50%;

    display: flex;
    align-items: center;
    justify-content: start;
    @include breakpoint($bk-phone) {
      padding: 24px;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 100vh;
      background-position-y: 100px;
    }

    .BlockForTitle {
      height: auto;
      width: 30%;
      border-radius: 40px;
      background-color: #1C1C1C;

      display: flex;
      flex-flow: column;
      align-items: center;

      padding: 40px;
      margin-left: 12%;
      margin-top: 2%;
      @include breakpoint($bk-phone) {
        margin: 0;
        width: 100%;
      }
      @include breakpoint($bk-table-landscape) {
        margin-right: 5%;
      }

      .NameTitle {
        color: #ffffff;
        font-family: "Kaspersky Sans Display", sans-serif;
        margin: 12px 0 12px 0;
      }
    }
  }

  .SecondBlockWrapper {
    width: 100%;
    height: auto;

    .SecondBlock {
      width: auto;
      height: auto;

      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .ScreensWrapper {
        display: flex;
        justify-content: center;
        width: 1200px;
        @include breakpoint($bk-phone) {
          width: 100%;
          flex-flow: column;
        }

        .ImgStyle {
          width: 100%;
          height: 100%;
          border-radius: 20px;
          padding: 12px;
        }
      }
    }
  }

  .ThirdBlockWrapper {
    width: 100%;
    height: auto;
    .ThirdBlock {
      width: auto;
      height: auto;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ScreensWrapper {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 1200px;

      .ImgStyle {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 12px;
      }
    }
  }

  //<-------------------------------------------------------- Figma Show Export
  .WrapperForFigma {
    width: 100%;
    height: 70vh;

    display: flex;
    flex-flow: column;
    justify-content: center;

    .FigmaExport {
      width: auto;
      height: 100%;

      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: start;
      margin: 24px;
      @include breakpoint($bk-phone) {
        flex-flow: column;
      }
    }
  }
}
