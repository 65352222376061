@import "~breakpoint-sass";
@import "./src/index.scss";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.WrapperTitle {
  width: 100%;
  padding: 24px 0 24px 0;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  word-wrap: break-word;
}


.TitleBig {
  font-size: 40px;
  padding: 0 0 8px 0;
}
.Text {
  width: 100%;
  word-wrap: break-word;
  font-size: 26px;
  color: #2f2f2f;
}
.Subtitle {
  font-size: 20px;
  color: #585757;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.description {
  font-size: 18px;
  color: #969696;
  font-weight: 500;
}

@include breakpoint($bk-table-landscape) {
  .TitleBig {
    font-size: 38px;
  }
  .Text {
    font-size: 26px;
  }
  .Subtitle {
    font-size: 20px;
  }
  .description {
    font-size: 16px;
  }
}
@include breakpoint($bk-table) {
  .Text {
    font-size: 26px;
  }
  .Subtitle {
    font-size: 18px;
  }
  .description {
    font-size: 14px;
  }
}
@include breakpoint($bk-phone) {
  .TitleBig {
    font-size: 30px;
  }
  .Text {
    font-size: 24px;
  }
  .Subtitle {
    font-size: 20px;
  }
  .description {
    font-size: 18px;
  }
}
