@import "~breakpoint-sass";
@import "./src/index.scss";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

@include breakpoint($bk-phone) {
  .ReactTooltip {
    display: none;
  }
}

//noinspection CssInvalidPropertyValue
.Blob {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: clip;
  overflow-y: visible;
  z-index: -1;
  @include breakpoint($bk-phone) {
    justify-content: normal;
  }
}

.Link {
  color: #212121;
  transition: 0.2s;
}

.Link:hover {
  color: #2130FD;
  transition: 0.2s;
}

.StickButtonWrapper {
  width: max-content;
  position: fixed;
  z-index: 10;
  top: 24px;
  right: 24px;

  @include breakpoint($bk-phone) {
    top: 4%;
  }
}

.WidthButton {
  z-index: 5;
  position: fixed;
  top: 24px;
  left: 1.5rem;
  height: 56px;
  width: 56px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(70, 63, 192, 0.06);
  background-color: white;
  outline: none;
  border: none;
  display: none;

  &:hover {
    border: 2px solid #2130FD;
  }

  @include breakpoint($bk-desktop) {
    display: block;
  }
}

.Wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 18px;
  transition: width 0.3s ease; /* Добавляем анимацию перехода */

  &.maxWidth {
    max-width: 1280px;
    width: 100%;
  }

  &.autoWidth {
    width: auto;
  }

  //<-------------------------------------------------------- Первый блок
  .header {
    height: auto;
    display: flex;
    flex-flow: column;

    .FirstBlock {
      height: 100vh;
      width: auto;
      padding: 0 24px;

      @include breakpoint($bk-phone) {
        display: flex;
        flex-flow: column;
        margin-top: 6rem;
        height: auto;
      }

      @include breakpoint($bk-table) {
        display: flex;
        flex-flow: column;
        margin-top: 6rem;
        height: auto;
      }

      .AvatarWrapper {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
        justify-content: center;
        align-items: center;
        justify-items: center;

        @include breakpoint($bk-phone) {
          display: flex;
          flex-flow: column;
          justify-content: start;
          align-items: start;
          padding: 24px 0 0 0;
          height: auto;
        }

        @include breakpoint($bk-table) {
          display: flex;
          flex-flow: column;
          justify-content: start;
          align-items: start;
          padding: 24px 0 0 0;
          height: auto;
        }

        .Avatar {
          background-image: url("../../../public/Resume/Me.webP");
          background-size: cover;
          background-repeat: no-repeat;
          height: 60%;
          width: 100%;
          border-radius: 10%;
          display: grid;
          align-items: end;
          @include breakpoint($bk-phone) {
            width: 120px;
            height: 120px;
            border-radius: 50%;
          }

          @include breakpoint($bk-table) {
            width: 300px;
            height: 300px;
            border-radius: 100%;
          }
        }
      }
    }
  }

  //-------< Contatcs
  .WrapperContacts {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 48px 24px 48px 24px;

    @include breakpoint($bk-phone) {
      grid-template-columns: 1fr;
      gap: 0;
    }
    @include breakpoint($bk-table) {
      grid-template-columns: 100%;
    }

    .Contacts {
      height: 100%;
      width: 100%;
    }

    .WorkStatus {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: end;

      .Statusdiv {
        text-decoration: none;
        color: #1c1c1c;
        cursor: none;
        height: 120px;
        width: 100%;
        background-color: #e0e4fb;
        box-shadow: 0 4px 12px rgba(70, 63, 192, 0.06);
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;

        a {
          text-decoration: none;
          color: #080808;
        }

        @include breakpoint($bk-phone) {
          width: 100%;
          flex-flow: column;
          text-align: center;
        }
      }

      .Statusdiv:hover {
        color: white;
        background-color: #2130FD;
        transition: 0.2s;
      }

      @include breakpoint($bk-phone) {
        margin-top: 24px;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
    }
  }


  //<-------------------------------------------------------- Бегущаяя строка
  .ScrollLineWrapper {
    width: auto;
    height: auto;
    margin: 12px 24px;

    .ScrollLine {
      width: auto;
      height: 100%;
      border-radius: 18px;
      display: flex;
      justify-content: space-between;
      transition: 0.2s;
      color: #212121;

      @include breakpoint($bk-phone) {
        margin: 12px 0;
      }

      .ScrollLineBlock {
        width: auto;
        display: flex;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        margin: 0 12px 0 12px;

        .ScrollLineItem {
          margin-right: 12px;
        }
      }
    }
  }

  .ScrollLine:hover {
    @include breakpoint($bk-phone) {
      border-radius: 0px;
    }
  }

  .About {
    width: auto;
    height: 100%;
    transition: 0.2s;

    .text {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: start;
      max-width: 900px;
      width: auto;
      height: 100%;
      font-family: Gilroy, sans-serif;
      font-weight: 400;
      font-size: 24px;
      transition: 0.2s;

      a {
        color: #212121;
      }

      @include breakpoint($bk-table-landscape) {
        width: auto;
        font-size: 20px;
      }
      @include breakpoint($bk-table) {
        width: auto;
        padding: 0 24px 0 24px;
        font-size: 24px;
      }
      @include breakpoint($bk-phone) {
        font-size: 20px;
        width: auto;
        //margin: 0 24px;
      }

      .HideText {
        opacity: 0.2;
        transition: 2s;
        @include breakpoint($bk-phone) {
          opacity: 0.7;
        }
      }

      .HideText:hover {
        opacity: 1;
        transition: 0.3s;
        font-weight: 500;
      }
    }
  }

  //<-------------------------------------------------------- Опыт работы

  details {
    width: 100%;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    .sum {
      background: #F7F7F7;
      width: auto;
    }
  }

  details[open] {
    padding-bottom: 12px;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;

    .sum {
      transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
      background-color: #F2F2F2;
    }

    .sum:hover {
      background-color: #2130FD;
      transition: 0.2s;
      color: #ffff;
    }

    p {
      color: #585757;
    }
  }

  .sum {

    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    width: 100%;
    padding: 12px 12px 12px 12px;
    border-radius: 12px;
    margin: 12px 0 12px 0;
    background-color: #E3E5FF;
  }

  .sum:hover {
    background-color: #2130FD;
    transition: 0.2s;
    color: #ffff;
  }

  a {
    text-decoration: none;
  }

  .ThirdBlock {
    height: auto;
    width: auto;
    display: flex;
    flex-flow: column;

    .WorkExpWrapper {
      width: auto;
      min-height: 100vh;
      height: auto;
      display: flex;
      flex-flow: column;
      padding: 48px 24px 48px 24px;

      @include breakpoint($bk-phone) {
        padding: 24px 24px 24px 24px;
        height: auto;
      }

      .TitleWrapper {
        display: flex;
        align-items: center;

        .LogoWork {
          height: 120px;
          width: 120px;
        }
      }

      .WorkExp {
        width: auto;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        gap: 48px 24px;
        margin-top: 24px;

        @include breakpoint($bk-phone) {
          display: flex;
          flex-flow: column;
        }

        @include breakpoint($bk-table) {
          display: flex;
          flex-flow: column;
        }
        @include breakpoint($bk-table-landscape) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
        }

        img {
          width: 90%;
          height: auto;
        }
      }


      .ContentBlock {
        width: auto;
        display: flex;
        flex-flow: column;
        justify-content: start;
        padding: 0 24px 20px 24px;
        transition: 0.2s;

        background-color: white;
        border-radius: 18px;
        -webkit-box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);
        -moz-box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);
        box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);

        @include breakpoint($bk-phone) {
          margin: 12px 0 0 0;
        }

        .WorkImg:empty {
          margin-left: 10px;
          height: 28px;
          width: 28px;
        }

        .LinkWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: auto;
          padding: 12px 0 12px 0;
          margin-top: 12px;
          border-radius: 12px 12px 0 0;
          transition: 0.2s;
          color: #585757;

          img {
            height: 16px;
            width: 16px;
          }
        }

        .LinkWrapper:hover {
          transition: 0.2s;
          background-color: #2130FD;
          color: #fff;
          padding: 12px;
          @include breakpoint($bk-table-landscape) {
            font-size: 16px;
          }

          a {
            text-decoration: #ffffff;
          }
        }
      }

      img {
        height: 80px;
        width: 80px;
        object-fit: cover;
      }

      .Tinkoff {
        -webkit-box-shadow: 0px 5px 14px 3px rgba(0, 119, 255, 0.05);
        -moz-box-shadow: 0px 5px 14px 3px rgba(0, 119, 255, 0.05);
        box-shadow: 0px 5px 14px 3px rgba(0, 119, 255, 0.05);
      }

      .ContentBlock:hover {
        //background-color: #F1F2FF;
        transition: 0.2s;
        @include breakpoint($bk-phone) {
          margin: 12px 0 0 0;
        }
      }
    }
  }

  //<-------------------------------------------------------- Образование
  .FourthBlock {
    height: auto;
    width: auto;
    display: flex;
    flex-flow: column;

    .WorkExpWrapper {
      width: auto;
      height: 100%;
      display: flex;
      flex-flow: column;
      padding: 0 24px 24px 24px;

      @include breakpoint($bk-phone) {
        padding: 24px 24px 48px 24px;
      }

      .TitleWrapper {
        display: flex;
        align-items: center;

        .LogoWork {
          height: 120px;
          width: 120px;
        }
      }

      .WorkExp {
        width: auto;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: auto;
        margin-top: 24px;
        gap: 48px 24px;

        @include breakpoint($bk-phone) {
          display: flex;
          flex-flow: column;
        }

        @include breakpoint($bk-table) {
          display: flex;
          flex-flow: column;
        }
        @include breakpoint($bk-table-landscape) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
        }
      }

      .Three {
        display: flex;
        flex-flow: column;
        gap: 24px;
      }

      .ContentBlock {
        width: auto;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 0 24px 24px 24px;
        transition: 0.2s;
        background-color: white;
        gap: 16px;

        border-radius: 18px;
        -webkit-box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);
        -moz-box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);
        box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);

        @include breakpoint($bk-phone) {
          margin: 12px 0 0 0;
        }

        .WorkImg {
          margin-left: 10px;
        }

        .details {
          .sum {
            background: #F7F7F7;
          }

          @include breakpoint($bk-phone) {
            width: auto;
          }
        }

        details[open] {
          padding-bottom: 12px;

          .sum {
            background-color: #F2F2F2;
          }

          .sum:hover {
            background-color: #2130FD;
            transition: 0.2s;
            color: #ffff;
          }

          p {
            color: #585757;
          }
        }

        .sum {
          width: auto;
          padding: 12px 0 12px 12px;
          border-radius: 12px;
          transition: 0.2s;
          margin: 12px 0 12px 0;
          background-color: #E3E5FF;
        }

        .sum:hover {
          background-color: #2130FD;
          transition: 0.2s;
          color: #ffff;
        }

        a {
          text-decoration: none;
          text-decoration: #ffffff;
        }

        .LinkWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: auto;
          padding: 12px 0 12px 0;
          margin-top: 12px;
          border-radius: 12px 12px 0 0;
          transition: 0.2s;
          color: #585757;

          img {
            height: 16px;
            width: 16px;
          }
        }

        .LinkWrapper:hover {
          transition: 0.2s;
          background-color: #2130FD;
          color: #fff;
          padding: 12px;
          @include breakpoint($bk-table-landscape) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.WrapperModalWindow {
  width: 100%;
}

.ModalWindow {
  width: 100%;
  height: auto;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.WrapperCloseModalWindow {
  position: sticky;
  z-index: 5;
  width: 100%;
  height: 0px;
  display: flex;
  top: 6%;
  justify-content: right;
  @include breakpoint($bk-phone) {
    top: 25%;
  }

  .CloseModalWindow {
    position: sticky;
    right: 1.4%;
    z-index: 5;

    width: 75px;
    height: 75px;
    border-radius: 100%;
    background-image: url("../../../public/Resume/close.svg");
    background-size: 65%;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.2s;
    background-color: #ffffff;
    opacity: 50%;
    border: 1px solid #1C1C1C;
    @include breakpoint($bk-phone) {
      right: 2%;
      width: 45px;
      height: 45px;
    }
  }

  .CloseModalWindow:hover {
    transition: 0.2s;
    background-color: #ACB1FE;
    opacity: 100%;
  }
}

.Cat {
  width: 100%;
  object-fit: cover;
}

