@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 24px; // 2.2%
  top: 48px; // 5%
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #2130FD;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 120px;
  height: 120px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  //height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* General sidebar styles */
.bm-menu {
  background: #2130FD;
  padding: 2.5em 1.5em 0;
  font-size: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 24px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.menu-item {
  //height: 100vh;
  overflow: auto;
  width: 100%;
  font-size: 34px;
  padding: 42px;
  Color: #ffffff;
  transition: 0.4s;
  text-decoration: none;
  @include breakpoint($bk-phone) {
    font-size: 28px;
  }
  @include breakpoint($bk-table-landscape) {
    font-size: 34px;
  }
  @include breakpoint($bk-table) {
    font-size: 28px;
  }
}

.Designer {
  font-size: 24px;
}

.menu-item:hover {
  color: #FDEE21;
  transition: 0.4s;
  text-decoration: #FDEE21;
}

.menu-item:hover:after {
  position: fixed;
  content: '👀';
  transition: 0.4s;
}
