@import "~breakpoint-sass";
@import "./src/index.scss";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.WrapperButton {
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-flow: column;
  gap: 16px;
  z-index: 999;
}

.Button {
  height: 64px;
  width: 100%;
  gap: 12px;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 12px rgba(70, 63, 192, 0.06);
  border-radius: 12px;
  transition: ease-in-out 0.2s;
  padding: 12px 16px;

  @include breakpoint($bk-phone) {
    font-size: 16px;
    height: 56px;
  }

  &:hover {
    background-color: #2130FD;
    transition: ease-in-out 0.2s;

    p {
      color: white;
      transition: ease-in-out 0.2s;
    }
  }
}