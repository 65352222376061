@import "~breakpoint-sass";
$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.MainWrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow: hidden;

  width: auto;
  height: 100%;

  .FirstBlock {
    width: auto;
    height: 100vh;
    background-color: black;
    background-image: url("../../../../public/Kaspersky/black.gif");
    background-repeat: no-repeat;
    background-size: 50%;

    display: flex;
    align-items: center;
    justify-content: start;
    @include breakpoint($bk-phone) {
      padding: 24px;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 100vh;
      background-position-y: 45px;
    }

    .BlockForTitle {
      height: auto;
      width: 700px;
      border-radius: 40px;
      background-color: #00A88E;

      display: flex;
      flex-flow: column;
      align-items: center;

      padding: 40px;
      margin-left: 12%;
      margin-top: 2%;
      @include breakpoint($bk-phone) {
        margin: 0;
      }
      @include breakpoint($bk-table-landscape) {
        margin-right: 5%;
      }

      .NameTitle {
        color: #ffffff;
        font-family: "Kaspersky Sans Display", sans-serif;
        margin: 12px 0 12px 0;
      }
    }
  }

  .SecondBlockWrapper {
    width: 100%;
    height: auto;
    background-color: white;
    .SecondBlock {
      width: auto;
      height: auto;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ScreensWrapper {
      display: flex;
      flex-flow: column;
      //justify-content: center;
      width: 80%;

      @include breakpoint($bk-phone) {
        width: auto;
      }

      .ImgStyle {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 12px;
        //margin: 24px;
      }
    }
  }

  //<-------------------------------------------------------- Галлерея
  .ThirdBlock {
    display: flex;
    flex-flow: column;
    width: auto;
    justify-content: center;
    height: 100%;
    margin-top: 24px;

    .ImgMaket {
      width: 100%;
      height: 100%;
    }

    .GalleryNavigation {
      display: flex;
      justify-content: center;

      .GalleryBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 45px;

        border-radius: 18px;
        border: 2px solid #080808;
        margin: 24px;
        transition: 0.4s;
        background-color: transparent;

        font-family: Gilroy, sans-serif;
        font-size: 18px;
      }

      .GalleryBtn:hover {
        background-color: #080808;
        transition: 0.4s;
        color: #C3CCE9;
      }
    }
  }

  //<-------------------------------------------------------- Figma Show Export
  .WrapperForFigma {
    width: 100%;
    height: 70vh;

    display: flex;
    flex-flow: column;
    justify-content: center;
    .FigmaExport {
      width: auto;
      height: 100%;

      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24px;
      @include breakpoint($bk-phone) {
        flex-flow: column;
      }
    }
  }
}
