@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

main {
  height: 100%;
  overflow: auto;
}

.Wrapper {
  height: 100%;
  width: auto;

  p, .SecondText {
    font-size: 18px;
    color: #878787;
  }

  .NavBar {
    height: auto;
    width: 100%;
    padding: 0 24px 0 24px;
    margin-top: 24px;
    gap: 16px;
    display: flex;
    //justify-content: end;
    align-items: center;

    font-size: 24px;
    font-weight: 300;

    .avatar {
      height: 56px;
      width: 56px;
      border-radius: 12px;
    }
  }

  .MainWrapper {
    width: 100%;
    min-height: 93svh;
    height: auto;
    padding: 40px 24px 48px 24px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      height: 100%;
    }
    
    @include breakpoint($bk-table) {
      display: flex;
      flex-flow: column;
      height: 100%;
    }

    .Left {
      width: 100%;
      height: 100%;
      border-radius: 16px;

      display: grid;
      grid-template-rows: 0.7fr 1fr;
      gap: 24px;

      @include breakpoint($bk-phone) {
        display: flex;
        flex-flow: column;
      }


      .CardWrapper {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        @include breakpoint($bk-phone) {
          display: flex;
          flex-flow: column;
        }

        .CardOne {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          background-color: #EFEFEF;
          padding: 24px 24px 24px 24px;


          .Title {
            font-size: 38px;
          }

          display: flex;
          flex-flow: column;
          justify-content: space-between;
          gap: 12px;
        }

        .WrapperCardTwo {
          width: 100%;
          height: 100%;

          display: grid;
          grid-template-rows: 1fr 0.3fr;
          gap: 24px;

          .CardTwo {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            background-color: #EFEFEF;
            padding: 24px 24px 24px 24px;
            display: grid;
            gap: 12px;

            h3 {
              font-size: 24px;
              font-weight: 300;
            }

            ul, li {
              display: flex;
              flex-flow: column;
              gap: 12px;
              text-transform: uppercase;

              a {
                color: #2130FD;
              }
            }
          }

          a {
            text-decoration: none;
          }
        }

        .CTA {
          width: 100%;
          height: 100%;
          color: white;
          background: radial-gradient(127.35% 123.95% at 88.58% 24.1%, rgb(115, 136, 255) 0%, rgb(202, 115, 255) 50.34250020980835%, rgb(255, 115, 157) 100%);
          border-radius: 16px;
          padding: 24px;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 400;
          transition: 0.4s;
        }

        .CTA:hover {
          background: blue;
          transition: 0.4s;
        }
      }

      .CardThree {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background-color: #EFEFEF;
        padding: 24px 24px 24px 24px;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #212121;
        font-size: 42px;
      }
    }

    .Right {
      width: 100%;
      height: 100%;
      min-height: 50vh;
      border-radius: 16px;
      background-color: #EFEFEF;
      background-image: url("../../../public/gif.gif");
      background-repeat: no-repeat;
      background-size: cover;

  
    }
  }
}
