// link-button.scss

.linkButton {
  margin-top: 16px;
  width: 100%;

  a {
    text-decoration: none;
  }
}

.ButtonLink {
  background-color: white;
  box-shadow: 0 4px 12px rgba(70, 63, 192, 0.06);
  color: #646464;
  padding: 28px 20px;
  border: none;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  transition: 0.4s;

  &:hover {
    transition: 0.4s;
    color: white;
    background-color: var(--button-hover-color, #2130FD);
  }

  @media (max-width: 768px) {
    padding: 24px 10px;
    font-size: 17px;
  }
}
