@import "~breakpoint-sass";
@import "../../../index";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.Wrapper {
  height: 100%;
  width: auto;
  //overflow: hidden;

  //<-------------------------------------------------------- Первый блок
  .FirstBlock {
    width: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include breakpoint($bk-phone) {
      height: 50vh;
    }

    .video {
      width: 100%;
      //height: 100%;
      //object-fit: cover;
      //position: absolute;
    }

    .TitleWrapper {
      height: 180px;
      width: 1100px;
      margin-top: 320px;
      border-radius: 18px;
      background-color: #fff;
      z-index: 2;

      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Gilroy, sans-serif;
      @include breakpoint($bk-phone) {
        width: auto;
        padding: 0 12px 0 12px;
      }
    }
  }

  //<-------------------------------------------------------- Второй блок // Ссылки на компании / Photo
  .SecondBlock {
    width: auto;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    .WrapperSecondBlock {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100%;
      height: 100%;
      @include breakpoint($bk-phone) {
        //grid-template-columns: 100%;
        display: flex;
        flex-flow: column;
      }
    }

    .LeftSide {
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 48px;
      background-color: #080808;

      .TextBlock {
        display: flex;
        background-color: #d91515;
        border-radius: 18px;
        @include breakpoint($bk-phone) {
          flex-flow: column;
        }

        .AboutCompanyText {
          font-size: 28px;
          text-align: left;
          font-weight: 800;
          margin: 24px;
          color: #080808;
          transition: 0.4s;
        }

        a:hover {
          color: #fff;
          transition: 0.4s;
        }
      }

      .MockUp {
        display: flex;
        justify-content: center;
        margin-top: 48px;

        .ImgMockup {
          width: 315px;
          height: 100%;
          transition: 0.4s;
        }
      }
    }

    .RightSide {
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .Img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .ThirdBlock {
    width: auto;
    height: auto;

    .WrapperThithBlock {
      width: auto;
      height: 100vh;
      display: flex;
      justify-content: center;
    }
  }

  .FourBlock {
    width: auto;
    height: auto;
    background-color: #d91515;

    .WrapperBtn {
      overflow: visible;
      position: sticky;
      top: 10%;
      @include breakpoint($bk-phone) {
        top: 12%;
      }
    }

    .WrapperFourBlock {
      height: auto;

      .AllMockUps {
        width: auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .Title {
          width: 1000px;
          padding: 48px;
          @include breakpoint($bk-phone) {
            width: auto;
          }
          @include breakpoint($bk-table-landscape) {
            width: auto;
          }
          @include breakpoint($bk-table) {
            width: auto;
          }
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          @include breakpoint($bk-phone) {
            width: 100%;
          }
        }

        .WrapperVideo {
          //overflow: hidden;
          height: 100%;
          width: 100%;
          background-color: #ffffff;
        }
      }
    }
  }

  .VideoMockUp {
    height: 500px;
    width: 100%;

    @include breakpoint($bk-phone) {
      width: 100%;
      position: center;
    }

    .Video {
      width: 100%;
      height: 100%;
    }
  }

  .FiveBlock {
    width: auto;
    height: auto;

    .WrapperFiveBlock {
      width: auto;
      height: 50vh;

      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #080808;
    }

    .Promo {
      width: 400px;
      height: 100px;

      z-index: 10;
      transition: 0.4s;
      background-color: #ffff;
      border-radius: 18px;
      padding: 48px;
      margin: 24px;

      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint($bk-phone) {
        width: auto;
        height: 64px;
        padding: 24px;
        font-size: 16px;
        margin-top: 48px;
      }

      .LinkPromo {
        text-decoration: none;
        color: #080808;
      }
    }

    .Promo:hover {
      transition: 0.4s;
      background-color: #d91515;
      color: #EBF2FA;
    }
  }
}
