@import "~breakpoint-sass";
@import "./src/index.scss";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

//<-------------------------------------------------------- Кейсы

.CaseTitleWrapper {
  padding: 24px;
  width: auto;
  border-radius: 18px;

  display: flex;
  flex-flow: column;
  background-color: #F7F7F7;
}

.WrapperCase {
  //min-height: 130vh;
  height: auto;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 48px;
  background-size: cover;
  @include breakpoint($bk-phone) {
    gap: 0;
  }

  .Casesimg {
    height: 700px;
    object-fit: cover;

    @include breakpoint($bk-phone) {
      height: 200px;
    }
  }

  .BadgeWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .WrapperForCases2023 {
    width: 100%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 24px;
    padding: 48px 24px;

    @include breakpoint($bk-phone) {
      grid-template-columns: auto;
      gap: 16px;
    }

    img {
      height: auto;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .VideoMockUp {
      width: 100%;
      height: auto;
    }

    .Video {
      width: 100%;
      height: auto;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .InfoCard {
    .detailBlock {
      margin-bottom: 24px;
    }
    img {
      height: 75px;
      width: auto;
      @include breakpoint($bk-phone) {
        height: 50px;
        width: auto;
      }
    }
    width: 100%;
    height: auto;
    border-radius: 18px;
    padding: 24px;
    transition: 0.4s;
    text-decoration: none;
    background-color: white;

    -webkit-box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);
    -moz-box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);
    box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);

    display: flex;
    flex-flow: column;
    align-items: start;

    @include breakpoint($bk-phone) {
      align-items: start;
      background-position-y: 210%;
      background-size: 100%;
    }
  }

  @include breakpoint($bk-phone) {
    width: 100%;
  }
}

.StylyShowButtonWrapper {
  width: 100%;
  height: 75px;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  color: #171717;
  background-color: #F7F7F7;
  border-radius: 18px;
  margin-top: 24px;
  border: 1px solid #171717;
  overflow: hidden;
  @include breakpoint($bk-phone) {
    height: 50px;
  }

  .Btn {
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F7;
    transition: 0.4s;
    outline: none;
    border: none;

    font-size: 34px;
    font-family: Gilroy, sans-serif;
    font-weight: 500;
    @include breakpoint($bk-phone) {
      font-size: 24px;
    }
  }

  .Btn:hover {
    transition: 0.4s;
    background: #E3E5FF;
  }
}

.WrapperForCases {
  height: auto;
  width: 100%;
  //min-height: 90vh;
  display: grid;
  justify-content: center;
  padding-top: 48px;

  @include breakpoint($bk-phone) {
    padding: 0;
  }
}

// Для блоков
.CasesBlock {
  height: auto;
  width: 100%;

  .Case2023 {
    width: 100%;
    height: auto;
  }

  .WrapperForBlocks {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 48px;
    padding: 0 24px 0 24px;

    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      padding: 24px;
    }
    @include breakpoint($bk-table) {
      display: flex;
      flex-flow: column;
      padding: 24px;
    }
    @include breakpoint($bk-table-landscape) {
      padding: 24px;
    }
  }

  .CaseContentBlock {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    max-width: 500px;
    padding: 24px;
    transition: 0.4s;
    text-decoration: none;

    display: flex;
    justify-content: center;


    .LogoSize {
      height: 100px;
      width: 100px;
    }
  }

  .CaseContentBlock:hover {
    transition: 0.4s;
  }

  .One {
    background-color: #1C1C1C;
    color: #E3E5FF;
    background-image: url("../../../public/Farsh/FARSHLogo.png");
    background-repeat: no-repeat;
    background-position-y: 90px;
    background-size: 100%;
    transition: 0.4s;

    box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
    0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
    0px 13.4018px 17.869px rgb(15 15 15 / 5%),
    0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
    0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
    0px 1.66035px 2.21381px rgb(15 15 15 / 2%);
  }

  .Two {
    background-color: #fff;
    color: #1C1C1C;
    background-image: url("../../../public/VkAssets/pattern.png");
    background-repeat: no-repeat;
    background-position-y: 150px;
    background-size: 100%;

    box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
    0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
    0px 13.4018px 17.869px rgb(15 15 15 / 5%),
    0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
    0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
    0px 1.66035px 2.21381px rgb(15 15 15 / 2%);
  }

  .Three {
    color: #FFFF;
    background-image: url("../../../public/Kaspersky/bg.gif");
    background-size: cover;
    box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
    0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
    0px 13.4018px 17.869px rgb(15 15 15 / 5%),
    0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
    0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
    0px 1.66035px 2.21381px rgb(15 15 15 / 2%);

    .LogoSize {
      border-radius: 20px;
    }
  }

  .Four {
    background-color: white;
    color: #1C1C1C;
    background-image: url("../../../public/OpenBank/bg.png");
    background-position: center;
    background-size: 150%;

    box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
    0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
    0px 13.4018px 17.869px rgb(15 15 15 / 5%),
    0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
    0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
    0px 1.66035px 2.21381px rgb(15 15 15 / 2%);
  }

  .Five {
    opacity: 20%;
    background-color: white;
    box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
    0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
    0px 13.4018px 17.869px rgb(15 15 15 / 5%),
    0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
    0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
    0px 1.66035px 2.21381px rgb(15 15 15 / 2%);
  }

  .Six {
    background-color: white;
    transition: 0.4s;
    opacity: 20%;

    box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
    0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
    0px 13.4018px 17.869px rgb(15 15 15 / 5%),
    0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
    0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
    0px 1.66035px 2.21381px rgb(15 15 15 / 2%);
  }

  .Seven {
    background-color: white;
    color: black;
    background-image: url("../../../public/Resume/free.png");
    background-repeat: no-repeat;
    background-size: 100%;
    transition: 0.4s;

    box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
    0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
    0px 13.4018px 17.869px rgb(15 15 15 / 5%),
    0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
    0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
    0px 1.66035px 2.21381px rgb(15 15 15 / 2%);
  }

  .Eight {
    background-color: #ffffff;
    background-image: url("../../../public/tlogo.png");
    color: black;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
    background-position-y: 170px;
    transition: 0.4s;

    box-shadow: 0px 60px 80px rgb(15 15 15 / 8%),
    0px 25.0666px 33.4221px rgb(15 15 15 / 6%),
    0px 13.4018px 17.869px rgb(15 15 15 / 5%),
    0px 7.51293px 10.0172px rgb(15 15 15 / 4%),
    0px 3.99006px 5.32008px rgb(15 15 15 / 3%),
    0px 1.66035px 2.21381px rgb(15 15 15 / 2%);
  }

}

// Для списка
.CasesList {
  width: 100%;

  .CaseLinks {
    width: 100%;
    min-width: 1400px;
    background-color: #fff;
    padding: 12px;
    border-radius: 18px;
    margin: 0 24px 0 24px;

    @include breakpoint($bk-phone) {
      width: auto;
      min-width: auto;
    }
    @include breakpoint($bk-table) {
      width: auto;
      min-width: auto;
    }
    @include breakpoint($bk-table-landscape) {
      width: auto;
      min-width: 100%;
    }

    .IconLink {
      height: 34px;
      width: 34px;
      @include breakpoint($bk-phone) {
        height: 16px;
        width: 16px;
      }
    }

    .CaseLinkWrapper {
      color: #080808;
      display: flex;
      justify-content: space-between;
      width: auto;
      align-items: center;
      border-radius: 18px;
      transition: 0.4s;
      padding: 12px 48px 12px 48px;

      font-size: 28px;
      font-weight: 400;
      font-family: Montserrat, sans-serif;
      text-decoration: none;

      @include breakpoint($bk-phone) {
        padding: 12px 24px 12px 24px;
        font-size: 16px;
      }
    }

    .CaseLinkWrapper:hover {
      transition: 0.4s;
      background-color: #E3E5FF;
    }
  }
}

/* Ваши стили для галереи */
.customAwesomeSlider {
  border-radius: 8px;
  overflow: hidden; /* Обрезать содержимое для соответствия закругленным углам */

  button {
    cursor: none;
  }

  nav {
    z-index: 100;
    position: absolute;
    bottom: 8px;

    button {
      &:hover {
        background-color: #2130FD;
        cursor: none;
      }
    }
  }
}


