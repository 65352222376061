@import "~breakpoint-sass";
@import "../../../index";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

.Wrapper {
  width: auto;
  height: auto;
  overflow: hidden;
  flex-flow: column;
  justify-content: center;
  padding: 48px 0;

  .Header {
    height: 100vh;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    @include breakpoint($bk-phone) {
      height: auto;
      width: auto;
      margin: 120px 24px;
      font-size: 13px;
    }

    .WrapperContent {
      width: auto;
      justify-content: center;
      padding: 48px;
      background-color: #fff;
      box-shadow: 0 8px 12px rgba(120, 63, 192, 0.06);
      border-radius: 18px;
      display: flex;
      color: #2130FD;
      transition: 1s ease-in-out;

      @include breakpoint($bk-phone) {
        flex-flow: column;
      }
      @include breakpoint($bk-table) {
        flex-flow: column;
      }
    }
  }

  .BlockWrapper {
    width: 100%;
    height: auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 82px;
    justify-content: center;
    padding: 0 24px;

    @include breakpoint($bk-phone) {
      display: flex;
      flex-flow: column;
      gap: 80px;
    }

    @include breakpoint($bk-table) {
      display: flex;
      flex-flow: column;
    }

    .Block {
      width: auto;
      height: auto;
      background-color: white;

      display: grid;
      gap: 24px;

      border: 1px solid #f0f0f0;
      box-shadow: 0 8px 12px rgba(120, 63, 192, 0.06);

      border-radius: 24px;
      margin-bottom: 24px;

      @include breakpoint($bk-phone) {
        min-height: 0;
      }
      @include breakpoint($bk-table) {
        min-height: 0;
      }
    }

    .ImageWrapper {
      padding: -12px -12px;
      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
      width: 100%;
      height: auto;
      display: block;
      align-content: start;

      @include breakpoint($bk-phone) {
        height: auto;
        max-height: 450px;
      }

      @include breakpoint($bk-table) {
        height: auto;
      }

      .ImgStyle {
        width: 100%;
        height: 100%;
        border-radius: 24px 24px 0 0;

        object-fit: cover;
      }
    }
  }
}


.InfoCard {
  .detailBlock {
    margin-bottom: 24px;
  }

  img {
    height: 75px;
    width: auto;
    @include breakpoint($bk-phone) {
      height: 50px;
      width: auto;
    }
  }

  .BadgeWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  width: 100%;
  height: auto;
  border-radius: 18px;
  padding: 24px;
  transition: 0.4s;
  text-decoration: none;
  background-color: white;

  -webkit-box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);
  -moz-box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);
  box-shadow: 0px 5px 14px 3px rgba(0, 53, 114, 0.05);

  display: flex;
  flex-flow: column;
  align-items: start;

  @include breakpoint($bk-phone) {
    align-items: start;
    background-position-y: 210%;
    background-size: 100%;
  }
}