@import "~breakpoint-sass";

$bk-desktop: 1920px 1440px;
$bk-table-landscape: 1440px 811px;
$bk-table: 811px 611px;
$bk-phone: 611px 0px;

@mixin NotBlack {
  Color: #080808;
}

@mixin Gray {
  Color: #171717
}

@mixin bgNotBlack {
  Background: #080808;
}

@mixin bgGray {
  Background: #171717
}

@mixin border {
  border-radius: 16px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  cursor: none;
}


.Cursor {
  @include breakpoint($bk-phone) {
    display: none;
    opacity: 0;
  }
}

/* Style the header: fixed position (always stay at the top) */
.ProgressBar {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: #f1f1f1;
}

/* The progress container (grey background) */
.ProgressContainer {
  width: 100%;
  height: 8px;
  background: #ccc;
}

/* The progress bar (scroll indicator) */
.ProgressBar {
  height: 8px;
  background: #212121;
  width: 0%;
  border-radius: 0 18px 18px 0;
}

body {
  margin: 0;
  font-family: -apple-system, Manrope, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #F7F7F7;
  overflow-x: hidden;
}

::selection {
  background: #2130FD; /* Safari */
  color: #E3E5FF;
}

::-moz-selection {
  background: #E3E5FF; /* Firefox */
  color: #080808;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.Line {
  width: auto;
  border: 1px solid #080808;
}

.LodingLazy {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
}

/* Применить закругление углов к контейнеру галереи */
.awesome-slider {
  border-radius: 12px;
  overflow: hidden; /* Обрежет содержимое, чтобы соответствовать закругленным углам */
}

/* Если вы хотите закруглить также изображения в слайдерах */
.awesome-slider img {
  border-radius: 12px;
}



